import React from 'react';
import { DeviceMockup } from '../DeviceMockup/DeviceMockup';
import './styles.scss';

export enum CardBigImageLocation {
    LEFT = 'left',
    RIGHT = 'right',
};

interface properties {
    index: number;
    title: string;
    text: string;
    laptopImage: string;
    mobileImage: string | Array<string>;
    mobileUrl: string;
    backgroundImage: string;
    // imageLocation?: CardBigImageLocation;
};

export function CardBig({index, title, text, laptopImage, mobileImage, mobileUrl, backgroundImage}: properties) {

    return <article
        className={index % 2 === 1 ? 'cardBig--right' : 'cardBig'}
        style={{
            // backgroundImage: 'url('+backgroundImage+')',
            // backgroundRepeat: 'no-repeat',
            // backgroundSize: 'contain',
        }}
        >
        <figure
            className={(index % 2 === 1 ?  'cardBig__figure--right' : 'cardBig__figure')
            + (laptopImage && laptopImage.length > 0 ? '' : ' cardBig__figure__single')}
        >
            { typeof mobileImage === 'object' ? mobileImage.map((image, index) => {
                    return  <DeviceMockup
                        key={'mobileMockup-' + index}
                laptopImage={laptopImage}
                mobileImage={image}
                mobileUrl={mobileUrl} />
            })
            :
                <DeviceMockup
                laptopImage={laptopImage}
                mobileImage={mobileImage}
                mobileUrl={mobileUrl} />
            }
        </figure>
        <img
            alt={"Decorative background."}
            className={(index % 2 === 1 ?
                'cardBig__figure__background--right' :
                'cardBig__figure__background')}
            src={backgroundImage} />
        <h2 className={laptopImage && laptopImage.length > 0 ? 'cardBig__title' : 'cardBig__title__single'}>{title}</h2>
        {/*<h2 className={'cardBig__title'}>{title}</h2>*/}
        <p>{text}</p>
    </article>
}
