import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import './resources/fonts/inter.css';
import {store} from './redux/store';
import {Provider} from 'react-redux';
import * as serviceWorker from './serviceWorker';
import {MainContentContainer} from "./components/MainContentContainer/MainContentContainer";

// ReactDOM.render(
//     <React.StrictMode>
//         <Provider store={store}>
//             <NavigationTop/>
//         </Provider>
//     </React.StrictMode>,
//     document.getElementsByTagName("header")[0]
// );
// document.getElementById('rootHeader')

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <MainContentContainer/>
        </Provider>
    </React.StrictMode>,
    document.getElementsByTagName("main")[0]
);
// document.getElementById('rootMain')

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
