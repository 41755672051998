import React, {Fragment} from 'react';
import './styles.scss';

import {CardBig} from "../CardBig/CardBig";
import {Introduction} from "../Introduction/Introduction";

import thefieldScreenshot from '../../resources/images/thefield.maisieklaassen.nl.webp'
import thefieldScreenshotMobile from '../../resources/images/thefield.maisieklaassen.nl_pixel5_2.webp'
import laze1 from '../../resources/images/laze_1.webp';
import laze2 from '../../resources/images/laze_2.webp';
import mtgcatDesktop from '../../resources/images/mtgcat.maisieklaassen.nl.webp';
import mtgcatMobile from '../../resources/images/mtgcat.maisieklaassen.nl_pixel5_2.webp';

import tomatoes from '../../resources/images/tomato_plant_photo_by_dan_gold_small.webp';
import office from '../../resources/images/office_by_copernico_small.webp';
import mtgcards from '../../resources/images/mtg_cards_photo_by_maisie_klaassen_1_small.webp';


export function MainContentContainer() {
    // Placed in a main DOM-element.
    const mainProjects = [
        {
            title: 'MTG-CAT',
            text: 'Een progressive web app voor het organiseren van je collectie Magic The Gathering kaarten.' +
                ' Deze app is gebouwd om lijsten te genereren van kaarten die binnen één set vallen.' +
                '  Dit gebeurd op basis van data die wordt opgehaald van de Scryfall API.' +
                ' Hiermee is je eigen verzameling inzichtelijk te maken en kan je van iedere' +
                ' set waar je kaarten van hebt een lijst uitprinten.',
            laptopImage: mtgcatDesktop,
            mobileImage: mtgcatMobile,
            mobileUrl: 'mtgcat.maisieklaassen.nl',
            backgroundImage: mtgcards,
        },
        {
            title: 'Tiny Offices',
            text: 'In opdracht van TheField is een online omgeving ontworpen en gerealiseerd waarin een reservatie geplaatst' +
                ' wordt voor een Tiny Office. De medewerkers van TheField kunnen de Tiny Offices en bijbehorende reserveringen' +
                ' gebruiksvriendelijk beheren door middel van het ontwikkelde Content Management Systeem. Ontwikkeld als studieproject' +
                ' in Laravel en ReactJs, afgesloten met het eindcijfer 10.',
            laptopImage: thefieldScreenshot,
            mobileImage: thefieldScreenshotMobile,
            mobileUrl: 'thefield.maisieklaassen.nl',
            backgroundImage: office,
        },
        {
            title: 'Laze',
            text: 'Extra hulp en ondersteuning om je productiviteitscyclus ' +
                'te verbeteren door middel van ' +
                'een Pomodoro timer. Laze herinnert je niet alleen aan het ' +
                'starten en stoppen, ze verteld je ook om op tijd te beginnen ' +
                'met ontspannen aan het einde van je werkdag' +
                ' zodat je niet tot laat in de avond doorwerkt. ' +
                'Ontwikkeld in samenwerking met Tijmen Ennik als ' +
                'studieproject, eindcijfer 8,3.',
            laptopImage: '',
            mobileImage: [laze1, laze2],
            mobileUrl: 'app',
            backgroundImage: tomatoes,
        },
    ];

    return <Fragment>
        <Introduction/>
        {
            mainProjects.map((project, index) => {
                return <CardBig
                    key={'project' + index}
                    index={index}
                    title={project.title}
                    text={project.text}
                    laptopImage={project.laptopImage}
                    mobileImage={project.mobileImage}
                    mobileUrl={project.mobileUrl}
                    backgroundImage={project.backgroundImage}
                />
            })
        }

        {/*<Portfolio />*/}
    </Fragment>
}
