import React, { Fragment } from 'react';
import './styles.scss';
import SurfaceBook from "./mockups/laptop_surface_book";
import GooglePixel5SortaSage from './mockups/smartphone_google_pixel_5_sorta_sage';

export enum CardBigImageLocation {
    LEFT = 'left',
    RIGHT = 'right',
};

interface properties {
    laptopImage: string;
    mobileImage: string;
    mobileUrl: string;
    // imageLocation?: CardBigImageLocation;
};

export function DeviceMockup({laptopImage, mobileImage, mobileUrl}: properties) {

    return <Fragment>
        {laptopImage && laptopImage.length > 0 && <SurfaceBook image={laptopImage}/> }
        {mobileImage && mobileImage.length > 0 && mobileUrl && mobileUrl.length > 0 &&
        <GooglePixel5SortaSage
            image={mobileImage}
            url={mobileUrl}
            classCss={laptopImage && laptopImage.length > 0 ? 'mockup__pixel5' : 'mockup__pixel5_single'}/>}
    </Fragment>
}
