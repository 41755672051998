import React from "react";

interface properties {
  image: string;
};

function SurfaceBook({image}: properties) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      // width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 4122 2408"
      className={'mockup__surfacebook'}
    >
      <g clipPath="url(#clip0_1:3)">
        <g filter="url(#filter0_f_1:3)">
          <path
            fill="#3C3C3C"
            fillRule="evenodd"
            d="M3597.96 2382.53l-1537.27 1.76-1537.274-1.76-448.202-.53-18.615 4.51c-2.588.63-4.687 1.83-4.687 2.68L0 2404.46c0 .24.67.57 1.536.72 0 0 10.846 2.23 50.376 2.23H2000.95l59.74-.05 59.73.05h1949.04c39.53 0 50.38-2.23 50.38-2.23.87-.15 1.54-.48 1.54-.72l-51.92-15.27c0-.85-2.1-2.05-4.68-2.68l-18.62-4.51-448.2.53z"
            clipRule="evenodd"
          ></path>
        </g>
        <g filter="url(#filter1_i_1:3)">
          <path
            fill="url(#paint0_linear_1:3)"
            fillRule="evenodd"
            d="M1988.71 2308.22H496.078s-23.321 2.16-42.236-12.3c-17.176-13.14-14.145-17.46-24.737-17.46-5.214 0-9.273 4.94-9.273 15.63v45.16s-1.916 3.91-2.738 7.22c-.822 3.32-.897 6.24-.897 6.24s-3.685 2.31-5.513 5.83c-1.829 3.51-2.58 6.6-3.944 8.04l-8.067 9.48s31.952-7.05 48.313-9.48c16.361-2.43 1559.774-3.5 1559.774-3.5l-.05-.44c204.33.14 1660.85 1.22 1676.06 3.49 16.36 2.43 48.31 9.48 48.31 9.48l-8.06-9.48c-1.37-1.45-2.12-4.53-3.95-8.05-1.83-3.51-5.51-5.82-5.51-5.82s-.08-2.93-.9-6.24c-.82-3.32-2.74-7.22-2.74-7.22v-45.17c0-10.69-4.06-15.63-9.27-15.63-10.59 0-7.56 4.33-24.74 17.46-18.91 14.46-42.23 12.31-42.23 12.31H1988.76l-.05.45z"
            clipRule="evenodd"
          ></path>
        </g>
        <g clipPath="url(#clip1_1:3)">
          <rect
            width="4097"
            height="69"
            x="15"
            y="2327"
            fill="#D8D8D8"
            rx="6.5"
          ></rect>
          <mask
            style={{ maskType: "alpha" }}
            width="4097"
            height="69"
            x="15"
            y="2327"
            maskUnits="userSpaceOnUse"
          >
            <rect
              width="4097"
              height="69"
              x="15"
              y="2327"
              fill="#fff"
              rx="6.5"
            ></rect>
          </mask>
          <path
            fill="url(#paint1_linear_1:3)"
            fillRule="evenodd"
            d="M74.598 2396.55c-46.239 0-58.78-.17-58.78-.17-.87-.66-1.545-14.16-1.545-15.27v-59.35c0-3.86 3.112-9.31 5.717-12.16l18.729-20.48 2013.611 10.42L4088.2 2289l18.73 20.49c2.61 2.85 4.72 8.3 4.72 12.15V2381c0 1.11 1.32 15.6.45 16.27 0 0-13.54-.83-59.78-.83h-1960.4l-2017.322.11z"
            clipRule="evenodd"
          ></path>
          <mask
            id="mask1_1:3"
            style={{ maskType: "alpha" }}
            width="4099"
            height="109"
            x="14"
            y="2289"
            maskUnits="userSpaceOnUse"
          >
            <path
              fill="#fff"
              fillRule="evenodd"
              d="M74.598 2396.55c-46.239 0-58.78-.17-58.78-.17-.87-.66-1.545-14.16-1.545-15.27v-59.35c0-3.86 3.112-9.31 5.717-12.16l18.729-20.48 2013.611 10.42L4088.2 2289l18.73 20.49c2.61 2.85 4.72 8.3 4.72 12.15V2381c0 1.11 1.32 15.6.45 16.27 0 0-13.54-.83-59.78-.83h-1960.4l-2017.322.11z"
              clipRule="evenodd"
            ></path>
          </mask>
          <g mask="url(#mask1_1:3)">
            <g filter="url(#filter2_f_1:3)" opacity="0.6">
              <ellipse
                cx="2066.5"
                cy="2412.5"
                fill="#383838"
                rx="1982.5"
                ry="17.5"
              ></ellipse>
            </g>
            <g filter="url(#filter3_f_1:3)" opacity="0.6">
              <ellipse
                fill="#EBEBEB"
                rx="801"
                ry="30"
                transform="matrix(1 0 0 -1 2066.5 2392.75)"
              ></ellipse>
            </g>
          </g>
          <path
            fill="url(#paint2_linear_1:3)"
            fillRule="evenodd"
            d="M433.398 1851L.758 2330.38H3851.55c42.34 0 115.26-172.93 87.26-204.68-112.21-127.25-229.28-266.72-229.28-266.72L433.398 1851z"
            clipRule="evenodd"
          ></path>
          <g filter="url(#filter4_d_1:3)">
            <path
              fill="url(#paint3_linear_1:3)"
              fillRule="evenodd"
              d="M2042.28 2322.43h671.96c35.36 0 42.28 7.41 42.28 7.41 1.41.87 1.87 2.88 1.13 4.36 0 0-15.97 34.48-38.47 34.48h-668.63-645.17c-22.51 0-38.48-34.48-38.48-34.48-.74-1.48-.28-3.49 1.13-4.36 0 0 6.92-7.41 42.29-7.41h631.96z"
              clipRule="evenodd"
            ></path>
          </g>
        </g>
        <g filter="url(#filter5_i_1:3)">
          <rect width="122" height="17" x="700" fill="#9C9C99" rx="3.5"></rect>
        </g>
        <g filter="url(#filter6_i_1:3)">
          <rect width="245" height="17" x="952" fill="#9C9C99" rx="3.5"></rect>
        </g>
        <g filter="url(#filter7_i_1:3)">
          <path
            fill="url(#paint4_linear_1:3)"
            fillRule="evenodd"
            d="M494.009 7C455.347 7 424 38.344 424 77.007V2238.99c0 38.67 31.341 70.01 70.009 70.01H3635.99c38.66 0 70.01-31.34 70.01-70.01V77.007C3706 38.335 3674.66 7 3635.99 7H494.009zM437 2238.99V77.007C437 45.523 462.528 20 494.009 20H3635.99c31.49 0 57.01 25.512 57.01 57.007V2238.99c0 31.49-25.53 57.01-57.01 57.01H494.009c-31.485 0-57.009-25.51-57.009-57.01z"
            clipRule="evenodd"
          ></path>
        </g>
        <rect
          width="3256"
          height="2276"
          x="437"
          y="20"
          fill="#080909"
          rx="57"
        ></rect>
        <image href={image}
               width="3000"
               height="2000"
               x="561"
               y="159"
               className={'cardBig__figure__image'}
        />
        {/*<path fill="#1D2129" d="M561 159H3561V2159H561z">*/}
        {/*</path>*/}
      </g>
      <defs>
        <filter
          id="filter0_f_1:3"
          width="4148.56"
          height="52.597"
          x="-13.591"
          y="2368.41"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feBlend
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          ></feBlend>
          <feGaussianBlur
            result="effect1_foregroundBlur_1:3"
            stdDeviation="6.796"
          ></feGaussianBlur>
        </filter>
        <filter
          id="filter1_i_1:3"
          width="3332.41"
          height="99.065"
          x="398.673"
          y="2278"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feBlend
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          ></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="3"></feOffset>
          <feGaussianBlur stdDeviation="0.5"></feGaussianBlur>
          <feComposite
            in2="hardAlpha"
            k2="-1"
            k3="1"
            operator="arithmetic"
          ></feComposite>
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"></feColorMatrix>
          <feBlend in2="shape" result="effect1_innerShadow_1:3"></feBlend>
        </filter>
        <filter
          id="filter2_f_1:3"
          width="4083.04"
          height="153.044"
          x="24.978"
          y="2335.98"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feBlend
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          ></feBlend>
          <feGaussianBlur
            result="effect1_foregroundBlur_1:3"
            stdDeviation="29.511"
          ></feGaussianBlur>
        </filter>
        <filter
          id="filter3_f_1:3"
          width="1678.11"
          height="136.112"
          x="1227.44"
          y="2324.69"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feBlend
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          ></feBlend>
          <feGaussianBlur
            result="effect1_foregroundBlur_1:3"
            stdDeviation="19.028"
          ></feGaussianBlur>
        </filter>
        <filter
          id="filter4_d_1:3"
          width="1395.45"
          height="50.25"
          x="1364.55"
          y="2321.43"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="1"></feOffset>
          <feGaussianBlur stdDeviation="1"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0.972549 0 0 0 0 0.972549 0 0 0 0 0.972549 0 0 0 1 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1:3"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_1:3"
            result="shape"
          ></feBlend>
        </filter>
        <filter
          id="filter5_i_1:3"
          width="122"
          height="18"
          x="700"
          y="0"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feBlend
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          ></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="3"></feOffset>
          <feGaussianBlur stdDeviation="0.5"></feGaussianBlur>
          <feComposite
            in2="hardAlpha"
            k2="-1"
            k3="1"
            operator="arithmetic"
          ></feComposite>
          <feColorMatrix values="0 0 0 0 0.941176 0 0 0 0 0.941176 0 0 0 0 0.937255 0 0 0 1 0"></feColorMatrix>
          <feBlend in2="shape" result="effect1_innerShadow_1:3"></feBlend>
        </filter>
        <filter
          id="filter6_i_1:3"
          width="245"
          height="18"
          x="952"
          y="0"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feBlend
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          ></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="3"></feOffset>
          <feGaussianBlur stdDeviation="0.5"></feGaussianBlur>
          <feComposite
            in2="hardAlpha"
            k2="-1"
            k3="1"
            operator="arithmetic"
          ></feComposite>
          <feColorMatrix values="0 0 0 0 0.941176 0 0 0 0 0.941176 0 0 0 0 0.937255 0 0 0 1 0"></feColorMatrix>
          <feBlend in2="shape" result="effect1_innerShadow_1:3"></feBlend>
        </filter>
        <filter
          id="filter7_i_1:3"
          width="3282"
          height="2302"
          x="424"
          y="7"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feBlend
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          ></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset></feOffset>
          <feGaussianBlur stdDeviation="1"></feGaussianBlur>
          <feComposite
            in2="hardAlpha"
            k2="-1"
            k3="1"
            operator="arithmetic"
          ></feComposite>
          <feColorMatrix values="0 0 0 0 0.815686 0 0 0 0 0.815686 0 0 0 0 0.815686 0 0 0 1 0"></feColorMatrix>
          <feBlend in2="shape" result="effect1_innerShadow_1:3"></feBlend>
        </filter>
        <linearGradient
          id="paint0_linear_1:3"
          x1="-1286.71"
          x2="-1286.71"
          y1="2278"
          y2="2376.06"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff"></stop>
          <stop offset="0.112" stopColor="#F2F2F2"></stop>
          <stop offset="0.149" stopColor="#DDD"></stop>
          <stop offset="0.172" stopColor="#F9F9F9"></stop>
          <stop offset="0.326" stopColor="#F4F3F3"></stop>
          <stop offset="0.493" stopColor="#CACACA"></stop>
          <stop offset="0.613" stopColor="#fff"></stop>
          <stop offset="0.624" stopColor="#919191"></stop>
          <stop offset="0.645" stopColor="#8E8E8E"></stop>
          <stop offset="0.656" stopColor="#6E6E6E"></stop>
          <stop offset="0.677" stopColor="#6B6B6B"></stop>
          <stop offset="0.687" stopColor="#BBB"></stop>
          <stop offset="0.738" stopColor="#B9B9B9"></stop>
          <stop offset="0.747" stopColor="#DADADA"></stop>
          <stop offset="0.771" stopColor="#DADADA"></stop>
          <stop offset="0.78" stopColor="#909090"></stop>
          <stop offset="0.802" stopColor="#8E8E8E"></stop>
          <stop offset="0.812" stopColor="#BABABB"></stop>
          <stop offset="0.862" stopColor="#B8B8B9"></stop>
          <stop offset="0.875" stopColor="#A5A5A5"></stop>
          <stop offset="0.898" stopColor="#A3A3A3"></stop>
          <stop offset="0.91" stopColor="#D8D8D8"></stop>
          <stop offset="1" stopColor="#D8D8D8"></stop>
        </linearGradient>
        <linearGradient
          id="paint1_linear_1:3"
          x1="14.273"
          x2="4112.4"
          y1="2397.27"
          y2="2397.27"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B4B4B4"></stop>
          <stop offset="0.015" stopColor="#fff"></stop>
          <stop offset="0.034" stopColor="#F3F3F3"></stop>
          <stop offset="0.319" stopColor="#EAEAEA"></stop>
          <stop offset="0.506" stopColor="#E5E5E5"></stop>
          <stop offset="0.971" stopColor="#E8E8E8"></stop>
          <stop offset="0.987" stopColor="#FEFEFE"></stop>
          <stop offset="1" stopColor="#ADADAD"></stop>
        </linearGradient>
        <linearGradient
          id="paint2_linear_1:3"
          x1="0.758"
          x2="4118.75"
          y1="2537.92"
          y2="2537.92"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#939393"></stop>
          <stop offset="0.008" stopColor="#BFBFBF"></stop>
          <stop offset="0.025" stopColor="#E4E4E4"></stop>
          <stop offset="0.319" stopColor="#F4F4F4"></stop>
          <stop offset="0.507" stopColor="#BBB"></stop>
          <stop offset="0.678" stopColor="#F4F4F4"></stop>
          <stop offset="0.915" stopColor="#EAEAEA"></stop>
          <stop offset="0.987" stopColor="#DCDCDC"></stop>
          <stop offset="1" stopColor="#949494"></stop>
        </linearGradient>
        <linearGradient
          id="paint3_linear_1:3"
          x1="1366.55"
          x2="2758"
          y1="2368.68"
          y2="2368.68"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#878787"></stop>
          <stop offset="0.031" stopColor="#FAFAFA"></stop>
          <stop offset="0.035" stopColor="#F5F5F5"></stop>
          <stop offset="0.966" stopColor="#FAFAFA"></stop>
          <stop offset="0.97" stopColor="#FAFAFA"></stop>
          <stop offset="1" stopColor="#7D7D7D"></stop>
        </linearGradient>
        <linearGradient
          id="paint4_linear_1:3"
          x1="455.2"
          x2="455.2"
          y1="7"
          y2="2265.23"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#939393"></stop>
          <stop offset="0.527" stopColor="#ABACAC"></stop>
          <stop offset="0.818" stopColor="#BABBBB"></stop>
          <stop offset="1" stopColor="#909090"></stop>
        </linearGradient>
        <clipPath id="clip0_1:3">
          <path fill="#fff" d="M0 0H4122V2408H0z"></path>
        </clipPath>
        <clipPath id="clip1_1:3">
          <path
            fill="#fff"
            d="M0 0H4097V69H0z"
            transform="translate(15 2327)"
          ></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default SurfaceBook;
