import React from 'react';
import './styles.scss';
import myself from '../../resources/images/maisie_klaassen_square_small.webp';

export function Introduction() {

    return <article
        className={'introduction'}
    >
        <figure
            className={'introduction__figure'}
        >
            <img
                 alt=""
                 className={'introduction__figure__image'}
                 height={880}
                 width={880}
                 src={myself}
            />
            {/*<figcaption>A Japanese dragon</figcaption>*/}
        </figure>
        <h2
            className={'introduction__title'}
        >Maisie Klaassen</h2>
        <p> Hallo, ik ben Maisie! Sinds mijn jeugd heb ik een interesse gehad in
            het ontwikkelen van software en het bouwen van robots. In 2018 ben
            ik begonnen met de opleiding Informatica aan de Hogeschool Leiden. Hier
            volg ik de specialisatie interactie-technologie waarnaast ik de minor
            Robotica, Domotica en Automatisering heb afgerond.
        </p>
        {/*<p>*/}
        {/*    Het afgelopen jaar heb ik de minor Robotica, Domotica en Automatisering*/}
        {/*    gevolgd waar ik onder andere heb geleerd wat er komt kijken bij het bouwen*/}
        {/*    van robots. Deze heb ik afgerond met een [bereken cijfer]. Na mijn minor*/}
        {/*    heb ik stage gelopen bij Axians in Alphen aan den Rijn (voorheen Gillz).*/}
        {/*    Na het uitvoeren van onderzoek en het ontwikkelen van twee componenten heb*/}
        {/*    ik deze stage afgerond met een 8,5.*/}
        {/*</p>*/}
        <p>
            In mijn vrije tijd experimenteer ik met hardware (battlebot in progress)
            en ben ik bezig met het leren van nieuwe technologieën voor het ontwikkelen
            van software zoals Flutter. Ook experimenteer ik met machine learning. Hierbij
            ben ik op het moment bezig een interactie te realiseren tussen mens en machine
            waarbij er alleen een webcam nodig is. Het doel is dat de gebruiker met behulp
            van simpele handbewegingen een website kan navigeren.
        </p>
    </article>
}
